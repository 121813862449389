const VALIDATION_TYPES = {
  time: ['FAS-102', 'FAS-103'],
  numeric: ['GE-05.1', 'GE-05.2', 'GE-05.3', 'GE-05.4', 'GE-05.5'],
  decimal: ['CS-05', 'CS-11'],
  price: [
    'GF-30',
    'GF-31',
    'GF-32',
    'GF-33',
    'GF-34',
    'GF-35',
    'GF-36',
    'GF-37',
  ],
};

export const NON_REQUIRED_SPECIAL_VALIDATION_QUESTIONS = [
  'CS-11',
  'FAS-102',
  'FAS-103',
  'GE-05.1',
  'GE-05.2',
  'GE-05.3',
  'GE-05.4',
  'GE-05.5',
];
export const SPECIAL_VALIDATION_QUESTIONS =
  Object.values(VALIDATION_TYPES).flat();

const validateTime = (response) => /^[0-5][0-9]:[0-5][0-9]$/.test(response);
const validateNumeric = (response) => /^[0-9]\d*$/.test(response);
const validateDecimal = (response) =>
  /^(?!.*,\d{1,2}$)(100(\.0{1,2})?|(\d{1,2}(\.\d{1,2})?))$/.test(response);
const validatePrice = (response) =>
  /^(99(\.9{2})?|(\d{1,2}(\.\d{2})?))$/.test(response);
const validateBasicNumber = (response) => /^\d{1,2}$/.test(response);

const getErrorMessage = (type) => {
  switch (type) {
    case 'time':
      return 'Response must be in the format of MM:SS, and may only include numbers.';
    case 'numeric':
      return 'Response must be numbers only.';
    case 'decimal':
      return 'Response may only include whole numbers or numbers with a decimal. If a decimal is included, it may only have up to 2 digits.';
    case 'price':
      return 'Response may only include whole numbers or numbers with a decimal. If a decimal is included, it may only have up to 2 digits.';
    default:
      return 'Response may only include numbers and have no more than 2 digits.';
  }
};

export const validateSpecialQuestionResponse = (internalId, response) => {
  let validationType = '';
  if (VALIDATION_TYPES.time.includes(internalId)) {
    validationType = 'time';
  } else if (VALIDATION_TYPES.numeric.includes(internalId)) {
    validationType = 'numeric';
  } else if (VALIDATION_TYPES.decimal.includes(internalId)) {
    validationType = 'decimal';
  } else if (VALIDATION_TYPES.price.includes(internalId)) {
    validationType = 'price';
  }

  const isValid = (() => {
    switch (validationType) {
      case 'time':
        return validateTime(response);
      case 'numeric':
        return validateNumeric(response);
      case 'decimal':
        return validateDecimal(response);
      case 'price':
        return validatePrice(response);
      default:
        return validateBasicNumber(response);
    }
  })();

  const errorMessage = isValid ? '' : getErrorMessage(validationType);

  return { isValid, errorMessage };
};
